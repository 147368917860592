import React from 'react';
import {paramsToObject} from '../utils/functionUtils';

import {
  CtaBox,
  Faq,
  Footer,
  HeroSection,
  ImgStats,
  SplitWithImg,
  StatsBox,
  StepsBox,
  TopMenu,
  ComparisonTable,
  LogoSection,
  Metatags,
  MediaSection,
} from '../sections';
import {BasicForm} from '../molecules';

// eslint-disable-next-line react/prop-types
const IndexPage = ({location}) => {
  const queryString = typeof window !== 'undefined' && window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries();
  const queryParams = paramsToObject(entries);
  const appendQueryString = queryString.toString();

  const gotoForm = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  const navToNewApp = false;

  const pageId = 'GC12';

  return (
    <main className="bg-white-100">
      <Metatags />
      <TopMenu
        navToNewApp={navToNewApp}
        queryParams={queryParams}
        appendQueryString={appendQueryString}
      />
      <HeroSection
        rightComponent={
          <BasicForm
            isDark={false}
            hasTitle
            queryParams={queryParams}
            appendQueryString={appendQueryString}
            pageId={pageId}
            onlyBusinessType
            navToNewApp={navToNewApp}
            location={location}
          />
        }
      />
      <MediaSection />
      <ImgStats />
      <StatsBox />
      <StepsBox />
      <ComparisonTable />
      <SplitWithImg />
      <Faq />
      <LogoSection />
      <CtaBox appendQueryString={appendQueryString} navToNewApp={navToNewApp} />
      <Footer navToNewApp={navToNewApp} />
    </main>
  );
};

export default IndexPage;